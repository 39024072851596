import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataReq, DataWorksheet, WorksheetHeader } from '@shared/types/export.type';
import { Row, Workbook, Worksheet } from 'exceljs';

interface SizeMap {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(private translateService: TranslateService) {}

  exportExcel(dataReq: DataReq[], excelTitle: string): Promise<void> {
    const workbook = new Workbook();
    dataReq.forEach(data => {
      const worksheet: Worksheet = workbook.addWorksheet(data.title);
      const dataWorksheet: DataWorksheet = this.getWorksheetData(data);
      this.addHeadersStyle(worksheet, dataWorksheet.headers);
      this.addWorksheetContent(worksheet, dataWorksheet.data);
      worksheet.autoFilter = `A1:${String.fromCharCode(64 + dataWorksheet.headers.length)}1`;
      worksheet.views = [{ state: 'frozen', ySplit: 1 }];
    });
    return this.downloadExcel(workbook, excelTitle);
  }

  private downloadExcel(workbook: Workbook, excelTitle: string): Promise<void> {
    return workbook.xlsx.writeBuffer().then((data: any) => {
      function downloadBlob(blob: any, filename: any) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
      }
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      downloadBlob(blob, `${excelTitle}.xlsx`);
      return Promise.resolve();
    });
  }

  private addHeadersStyle(worksheet: Worksheet, worksheetHeader: WorksheetHeader[]): void {
    const headers: string[] = worksheetHeader.map(e => e.header);
    const headerRow: Row = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      cell.font = { color: { argb: 'ffffff' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '004b87' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    headers.forEach((_, index) => {
      worksheet.getColumn(index + 1).width = worksheetHeader[index].size * 1.2;
    });
  }

  private getWorksheetData(dataReq: DataReq): DataWorksheet {
    let headers: WorksheetHeader[];
    let excelColumns: string[] = [];
    const colSizeMap: SizeMap = {};

    if (dataReq.headers && dataReq.headers.length) {
      excelColumns = dataReq.headers.map(h => h.field) || [];
      headers = dataReq.headers
        .filter(e => e.header)
        .map(e => {
          const header: string = this.translateService.instant(e.header);
          return {
            header,
            size: header.length
          };
        });
    } else {
      excelColumns = Object.keys((dataReq.data || [])[0] || []);
      headers = excelColumns.map(head => ({
        header: head.toUpperCase().replace(/_/g, ' '),
        size: head.length
      }));
    }

    const data = (dataReq.data || []).map(e => {
      const elem = [];
      excelColumns.forEach((c, index) => {
        const value: string = e[c] || '';
        const colSize: number = colSizeMap[c] || 0;
        colSizeMap[c] = Math.max(headers[index].header.length, `${value}`.length, colSize);
        elem.push(value);
      });
      return elem;
    });

    headers.forEach((header, index) => {
      header.size = colSizeMap[excelColumns[index]];
    });

    return {
      data,
      headers
    };
  }

  private addWorksheetContent(worksheet: Worksheet, data: any[]) {
    data.forEach(d => {
      const row = worksheet.addRow(d);
      row.eachCell(cell => {
        cell.alignment = {
          vertical: 'middle'
        };
      });
    });
    worksheet.addRow([]);

    // DISABLE UNTIL USERS ACCEPTED
    // Footer Row
    /* const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }; */
  }
}
