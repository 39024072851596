import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdleService } from '@shared/services/idle.service';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService, public primeNGConfig: PrimeNGConfig, private injector: Injector) {
    this.injector.get(IdleService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.translate.stream('primeng').subscribe(data => {
        this.primeNGConfig.setTranslation(data);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
