import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isAccessTokenInvalidOrExpired()) {
      this.auth.logout();
    }
    return true;
  }
}
