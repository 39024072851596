import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DenyNotification } from '@shared/types/deny-notification.type';
import { NOTIFICATIONS } from '@shared/utils/constants';
import { getBaseURLs } from '@shared/utils/url.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public fileName: string;
  public dateCreationFile: string;

  constructor(private http: HttpClient) {}

  getMyNotifications(areaId: string) {
    return this.http.post<any>(getBaseURLs(NOTIFICATIONS) + `/notifications/me?wasConfirmed=false`, { areaId });
  }

  updateNotification(idNotification: string, body: any): Observable<Notification> {
    return this.http.put<Notification>(getBaseURLs(NOTIFICATIONS) + `/notifications/me/${idNotification}`, body);
  }

  denyBucketNotification(body: DenyNotification): Observable<DenyNotification> {
    return this.http.post<DenyNotification>(getBaseURLs(NOTIFICATIONS) + `/denynotifications/me`, body);
  }

  acceptBucketNotification(denyNotification: DenyNotification): Observable<DenyNotification> {
    return this.http.delete<DenyNotification>(getBaseURLs(NOTIFICATIONS) + `/denynotifications/me/${denyNotification.idDenyNotification}`);
  }

  getDenyNotification(idBucket: string): Observable<DenyNotification> {
    return this.http.get<any>(getBaseURLs(NOTIFICATIONS) + `/denynotifications/bucket/${idBucket}`);
  }

  clearAllMeNotifications(body: any): Observable<Notification> {
    return this.http.put<Notification>(getBaseURLs(NOTIFICATIONS) + `/notifications/me`, body);
  }
}
