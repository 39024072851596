import { SelectItem } from 'primeng/api';

export enum Language {
  ES = 'es_es',
  EN = 'en_gb',
  DE = 'de_de'
}

export const LanguageSelector: SelectItem[] = [
  { label: 'shared.header.languages.english', value: Language.EN }, // FIRST IS DEFAULT
  { label: 'shared.header.languages.deutsch', value: Language.DE },
  { label: 'shared.header.languages.spanish', value: Language.ES }
];

export const Languages = [
  { name: 'EN', code: Language.EN }, // FIRST IS DEFAULT
  { name: 'ES', code: Language.ES },
  { name: 'DE', code: Language.DE }
];

export const DEFAULT_LANG = LanguageSelector[0].value;
