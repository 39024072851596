import { storageConstants } from '@constants/storage.constant';
export class User {
  public areaId: string;
  public areasAdmin: string[];
  public areasAdminEntity: string[];
  public email: string;
  public entities: string[];
  public idLogin: string;
  public idUser: string;
  public name: string;
  public predLanguage: string;
  public isAdmin: boolean;

  constructor(obj?: Partial<User>) {
    Object.assign(this, obj);
  }

  public isSuperAdminiInCurrentArea(): boolean {
    let idAreaCurrent = '';
    if (localStorage.getItem(storageConstants.area)) {
      idAreaCurrent = JSON.parse(localStorage.getItem(storageConstants.area)).idArea;
    }
    return this.areasAdmin.includes(idAreaCurrent);
  }
}
