import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltersNotificationPeriod } from '@shared/types/filters.type';
import { NotificationPeriodCallOutput, NotificationPeriod, NotificationPeriodRequest } from '@shared/types/notification-period.type';
import { NOTIFICATIONS } from '@shared/utils/constants';
import { getBaseURLs } from '@shared/utils/url.util';
import { serializeParams } from '@shared/utils/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationPeriodService {
  constructor(private http: HttpClient) {}

  public getPeriodNotifications(filtersNotificationPeriod: FiltersNotificationPeriod): Observable<NotificationPeriod> {
    return this.http
      .get<NotificationPeriodCallOutput>(getBaseURLs(NOTIFICATIONS)+`/notifications/me/period`, {
        ...serializeParams(filtersNotificationPeriod)
      })
      .pipe(
        map(period => {
          return period.result.notificationResponse;
        })
      );
  }

  public savePeriodNotifications(filtersNotificationPeriod: FiltersNotificationPeriod, request: NotificationPeriodRequest): Observable<NotificationPeriod> {
    return this.http
      .post<NotificationPeriodCallOutput>(getBaseURLs(NOTIFICATIONS)+`/notifications/me/period`, request, {
        ...serializeParams(filtersNotificationPeriod)
      })
      .pipe(
        map(period => {
          return period.result.notificationResponse;
        })
      );
  }

  public deletePeriodNotifications(filtersNotificationPeriod: FiltersNotificationPeriod): Observable<any> {
    return this.http.delete(getBaseURLs(NOTIFICATIONS)+`/notifications/me/period`, {
      ...serializeParams(filtersNotificationPeriod)
    });
  }
}
