import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mapUrlParams } from '@shared/utils/url.util';

@Component({
  template: ''
})
export class OAuthCallbackComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    const parametersObj = mapUrlParams(this.route.snapshot.fragment);
    const access_token = parametersObj ? parametersObj['access_token'] : undefined;
    let url: string;

    if (access_token) {
      sessionStorage.setItem('access_token', access_token);
      const tokenStr = atob(access_token.split('.')[1]);
      const tokenObj: JSON = JSON.parse(tokenStr);
      this.storeTokenInformation(tokenStr, access_token, tokenObj);
      // TODO: set in app current_url before redirect to adfs
      url = sessionStorage.getItem('current_url');
    }
    if (url) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(['/']);
    }
  }

  private storeTokenInformation(strAccessToken, encodedAccessToken, jsonToken) {
    sessionStorage.setItem('strAccessToken', strAccessToken);
    sessionStorage.setItem('encodedAccessToken', encodedAccessToken);
    sessionStorage.setItem('tokenIssue', jsonToken.iat);
    sessionStorage.setItem('userId', jsonToken.CommonName);
  }
}
