import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { dateStandart, dateTimeStandart } from '@shared/constants/date.constant';
import { FieldType, TableField } from '@shared/types/folder-table.type';
import { ObjectUtils } from '@shared/utils/object.util';

@Pipe({
  name: 'getValueByField'
})
export class GetValueByFieldPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(object: any = {}, structure: TableField): unknown {
    let value;
    if (Array.isArray(structure.field)) {
      const values: string[] = structure.field.map(f => ObjectUtils.getObjectValueByKey(f as string, object));
      value = values.join(structure.separator || ' ');
    } else {
      value = ObjectUtils.getObjectValueByKey(structure.field as string, object);
    }
    if (structure.types) {
      structure.types.forEach(type => {
        switch (type) {
          case FieldType.DECIMAL:
            value = !isNaN(+value) ? (+value).toFixed(2) : value;
            break;
          case FieldType.NUMBER:
            value = !isNaN(+value) ? (+value).toFixed(0) : value;
            break;
          case FieldType.DATE:
            value = this.datePipe.transform(value, dateStandart);
            break;
          case FieldType.DATE_TIME:
            value = this.datePipe.transform(value, dateTimeStandart);
            break;
          case FieldType.ARRAY:
            value = value.join(structure.separator || ' ');
            break;
        }
      });
    }
    return value;
  }
}
