import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private _sidebarState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly sidebarState: Observable<boolean> = this._sidebarState.asObservable();

  // borrar
  isSidebarVisible: boolean;
  private sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
  constructor() {
    this.sidebarVisibilityChange.subscribe(value => {
      this.isSidebarVisible = value;
    });
  }

  toggleSidebarVisibility() {
    this._sidebarState.next(!this._sidebarState.getValue());
  }
}
