import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NotificationPeriodComponent } from './components/notification/notification-period/notification-period.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PipesModule } from './pipes/pipes.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [HeaderComponent, NotificationComponent, LogoutComponent, FooterComponent, NotificationPeriodComponent, PageNotFoundComponent],
  exports: [TranslateModule, HeaderComponent, NotificationComponent, FooterComponent],
  imports: [
    TranslateModule,
    FormsModule,
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    DropdownModule,
    ProgressBarModule,
    ButtonModule,
    DialogModule,
    CalendarModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    PipesModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule {}
