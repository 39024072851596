import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { storageConstants } from '@shared/constants/storage.constant';
import { Area } from '@shared/types/area.type';
import { OutputContract } from '@shared/types/generic-contract.type';
import { User } from '@shared/types/user.model';
import { USERS } from '@shared/utils/constants';
import { getBaseURLs } from '@shared/utils/url.util';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public _user: BehaviorSubject<User> = new BehaviorSubject(null);
  public user$: Observable<User> = this._user.asObservable();

  public _areas: BehaviorSubject<Area[]> = new BehaviorSubject(null);
  public areas$: Observable<Area[]> = this._areas.asObservable();

  public _area: BehaviorSubject<Area> = new BehaviorSubject(null);
  public area$: Observable<Area> = this._area.asObservable();

  constructor(private http: HttpClient) {}

  async getUser(): Promise<User> {
    const url = getBaseURLs(USERS)+`/users/me/admininfo`;
    const user: User = await this.http.get<User>(url).toPromise();
    localStorage.setItem(storageConstants.user, JSON.stringify(user));
    this._user.next(user);
    return user;
  }

  async getAreas(): Promise<Area[]> {
    const url = getBaseURLs(USERS)+`/areas/me`;
    const areas: Area[] = await this.http.get<Area[]>(url).toPromise();
    localStorage.setItem(storageConstants.areas, JSON.stringify(areas));
    const area: Area = localStorage.getItem(storageConstants.area) ? JSON.parse(localStorage.getItem(storageConstants.area)) : areas[0];
    localStorage.setItem(storageConstants.area, JSON.stringify(area));
    this._areas.next(areas);
    this._area.next(area);
    return areas;
  }

  getUsers(pageIndex?: number, pageSize?: number, orderBy?: string, asc?: boolean, filters?: any) {
    let endpoint = getBaseURLs(USERS)+`/users`;
    if (typeof pageIndex !== undefined && pageSize) {
      endpoint += `?pageIndex=${pageIndex}&pageSize=${pageSize}`;
      if (orderBy && typeof asc !== undefined) {
        endpoint += `&orderBy=${orderBy}&asc=${asc}`;
      }
      if (filters) {
        filters.map(filter => {
          endpoint += `&${filter.key}=${encodeURIComponent(filter.value)}`;
        });
      }
    }
    return this.http.get<any>(endpoint);
  }

  async getUserByCSID(csid: string): Promise<User> {
    const url = getBaseURLs(USERS)+`/users/${csid}/area/${this._area.value.idArea}`;
    const data = await this.http.get<OutputContract<{ userResponse: User }>>(url).toPromise();
    if (data.result) {
      return Promise.resolve(data.result.userResponse);
    } else {
      return Promise.reject(data.error);
    }
  }

  createUser(body: any) {
    return this.http.post<any>(getBaseURLs(USERS)+`/users`, body);
  }

  updateUser(idUser: string, body: any): Observable<User> {
    return this.http.put<User>(getBaseURLs(USERS)+`/users/${idUser}`, body);
  }

  deleteUser(idUser: string): Observable<User> {
    return this.http.delete<User>(getBaseURLs(USERS)+`/users/${idUser}`);
  }

  async updateUserLanguage(body: any): Promise<User> {
    const url = getBaseURLs(USERS)+`/users/me`;
    const user: User = await this.http.put<User>(url, body).toPromise();
    return user;
  }

  get currentUser(): User {
    return this._user.getValue();
  }

  get currentAreaId(): string {
    return this._area.getValue() ? this._area.getValue().idArea : '';
  }

  get isAdminInCurrentArea(): boolean {
    const area: Area = this._area.getValue();
    const user: User = this._user.getValue();
    if (area && user) {
      return user.areasAdminEntity.includes(area.idArea);
    }
    return false;
  }

  get isSuperAdminInCurrentArea(): boolean {
    const area: Area = this._area.getValue();
    const user: User = this._user.getValue();
    if (area && user) {
      return user.areasAdmin.includes(area.idArea);
    }
    return false;
  }
}
