import { HttpParams } from '@angular/common/http';

/**
 * Serialize params for sending to backend
 *
 * @param params any json object
 * @return object formated in HttpParams
 */
export const serializeParams = (params: any): { params: HttpParams } => {
  const reqOpts = { params: new HttpParams() };
  if (params) {
    for (const key in params) {
      if (key && Object.prototype.hasOwnProperty.call(params, key) && typeof params[key] !== 'undefined' && params[key] !== null && params[key] !== '') {
        if (params[key] instanceof Date) {
          reqOpts.params = reqOpts.params.append(key, formatDateToUTC(params[key]));
        } else if (Array.isArray(params[key])) {
          params[key].forEach((element: string) => {
            reqOpts.params = reqOpts.params.append(key, element);
          });
        } else {
          reqOpts.params = reqOpts.params.append(key, params[key]);
        }
      }
    }
  }
  return reqOpts;
};

/**
 *
 * Function to convert Date of Datepicker to format UTC
 *
 * @param event Date to convert a UTC
 * @return Date to UTC
 *
 */
export function formatDateToUTC(event: Date): string {
  const tmpDate = new Date(event);
  return new Date(`${tmpDate.getFullYear()}-${tmpDate.getMonth() + 1}-${tmpDate.getDate()} UTC`).toISOString();
}

export const format_YYYYMMDD = (date: string | number | Date, separator = ''): string => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join(separator);
};
