import { HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

export const mapUrlParams = (urlFragment: string): any => {
  const responseParameters = (urlFragment && urlFragment.split('&')) || [];
  return responseParameters.reduce((acc, param) => {
    const paramMap = param.split('=');
    if (paramMap.length === 2) {
      acc[paramMap[0]] = paramMap[1];
    }
    return acc;
  }, {});
};

export function buildUrlParamsIfExists(params: object): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  if (params) {
    Object.keys(params).forEach(key => {
      if ((Array.isArray(params[key]) && params[key].length > 0) || (!Array.isArray(params[key]) && (params[key] || String(params[key]) === 'false'))) {
        httpParams = httpParams.set(key, params[key]);
      }
    });
  }
  return httpParams;
}

export function getAdfsUrl() {
  const { openidUrl, openidClientId, responseType, openidResource, openidRedirectUrl } = environment.oauth;
  return `${openidUrl}?client_id=${openidClientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(openidRedirectUrl)}&resource=${openidResource}`;
}

export function getBaseURLs(service): string {
  const urlsArray = environment.urls;
  const foundUrlObj = urlsArray.find(urlObj => urlObj.name === service);
  let baseURL = foundUrlObj ? foundUrlObj.url : undefined;
  return baseURL;
}
