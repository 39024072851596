export class ObjectUtils {
  static getObjectValueByKey(key: string, object: any): any {
    let value = { ...object };
    if (key && key.length) {
      key.split('.').forEach((key: string) => {
        const regex = /(\w+)\[(\d+)\]/; // searches for the name of a list, its [] characters and the index of a list.
        const match = regex.exec(key);
        if (match && match.length === 3) {
          //if is an array
          const nameOfTheList = match[1];
          const indexOfTheList = match[2];
          value = value[nameOfTheList][indexOfTheList] || {};
        } else {
          //not an array
          value = value[key] || {};
        }
      });
      if (Object.keys(value).length === 0 && value.constructor === Object) {
        return null;
      } else {
        return value;
      }
    }
  }
}
