import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minute } from '@shared/constants/general.constant';
import { storageConstants } from '@shared/constants/storage.constant';
import { environment } from 'environments/environment';
import { Subject, fromEvent, merge } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private closeActivity;
  private warningActivity;
  private closeInactive: Subject<any> = new Subject();
  private warningInactive: Subject<any> = new Subject();
  private tokenExpire: Subject<any> = new Subject();

  constructor(private auth: AuthService, private translateService: TranslateService) {
    this.setTimeoutClose();
    this.setTimeoutWarning();
    this.tokenTimeout();
    this.idle();
    this.onExpire();
  }

  private reloadLang(): Promise<any> {
    const lang = localStorage.getItem(storageConstants.lang);
    return this.translateService.getTranslation(lang).toPromise();
  }

  private onExpire() {
    this.closeInactive.subscribe(() => {
      this.reloadLang().then(() => {
        Swal.close();
        Swal.fire({
          title: this.translateService.instant('general.tokenExpirationTitle'),
          text: this.translateService.instant('general.tokenExpiredText'),
          icon: 'error',
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.translateService.instant('admin.entities.ok')
        }).then(result => {
          if (result.isConfirmed) {
            this.auth.logout();
          }
        });
      });

      this.warningInactive.subscribe(() => {
        Swal.fire({
          title: this.translateService.instant('general.tokenExpirationTitle'),
          text: this.translateService.instant('general.tokenExpirationText'),
          icon: 'warning',
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.translateService.instant('admin.entities.ok')
        });
      });

      this.tokenExpire.subscribe(() => {
        Swal.fire({
          title: this.translateService.instant('general.tokenExpirationTitle'),
          text: this.translateService.instant('general.tokenExpirationText'),
          icon: 'warning',
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.translateService.instant('admin.entities.ok')
        });
      });
    });
  }

  private setTimeoutClose() {
    this.closeActivity = setTimeout(() => this.closeInactive.next(undefined), environment.idleTime);
  }

  private setTimeoutWarning() {
    this.warningActivity = setTimeout(() => this.warningInactive.next(undefined), environment.idleTime - 5 * minute);
  }

  private idle() {
    const clickEvents$ = fromEvent(document, 'click');
    const mouseMoveEvent$ = fromEvent(document, 'mousemove');
    const keyboardEvent$ = fromEvent(document, 'keyup');
    const events$ = merge(clickEvents$, mouseMoveEvent$, keyboardEvent$);
    events$.subscribe(() => {
      clearTimeout(this.closeActivity);
      clearTimeout(this.warningActivity);
      this.setTimeoutClose();
      this.setTimeoutWarning();
    });
  }

  private tokenTimeout() {
    const accessToken = sessionStorage.getItem('access_token');
    if (accessToken) {
      const decodedAccessToken: JSON = this.parseJwt(accessToken);
      const tokenExpireTime = decodedAccessToken['exp'];
      const timeRemaining = new Date(tokenExpireTime * 1000).getTime();
      setTimeout(() => this.tokenExpire.next(undefined), timeRemaining - new Date().getTime() - 10 * minute);
    }
  }

  private parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
}
