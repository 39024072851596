import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Area } from '@shared/types/area.type';
import { Bucket } from '@shared/types/bucket.type';
import { EntityBucket } from '@shared/types/entity-bucket.type';
import { User } from '@shared/types/user.model';
import { METADATA, USERS } from '@shared/utils/constants';
import { getBaseURLs } from '@shared/utils/url.util';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BucketService {
  public outboundAccess: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public user: User;
  public userId: string;

  constructor(private http: HttpClient, private messageService: MessageService, private translate: TranslateService) { }

  getAreas() {
    return this.http.get<Area[]>(getBaseURLs(USERS) + `/areas`);
  }

  getBuckets() {
    return this.http.get<any>(getBaseURLs(METADATA) + `/buckets?pageIndex=0&pageSize=50`);
  }

  getAllBuckets() {
    return this.http.get<any>(getBaseURLs(METADATA) + `/buckets`);
  }

  getAllBucketsAdminReport(): Promise<Bucket[]> {
    return this.http
      .get<{ content: Bucket[] }>(getBaseURLs(METADATA) + `/buckets?adminReport=Y`)
      .toPromise()
      .then(e => Promise.resolve(e.content))
      .catch(err => Promise.reject(err));
  }

  // TODO: VER DONDE SE LLAMA A ESTO
  getMyBuckets() {
    return this.http.get<any>(getBaseURLs(METADATA) + `/buckets/me`);
  }

  getMyInboundBuckets() {
    return this.http.get<Bucket[]>(getBaseURLs(METADATA) + `/inboundbuckets/me`);
  }

  getMyOutboundBuckets() {
    return this.http.get<Bucket[]>(getBaseURLs(METADATA) + `/outboundbuckets/me`);
  }

  getMyOutboundBucketsToUpload() {
    return this.http.get<Bucket[]>(getBaseURLs(METADATA) + `/outboundbuckets/me?upload=UPLD`);
  }

  getBucket(idBucket: string, isAdmin: boolean): Observable<Bucket> {
    return this.http.get<Bucket>(getBaseURLs(METADATA) + `/buckets/${idBucket}?isAdmin=${isAdmin}`);
  }

  getMyInboundBucket(idBucket: string): Observable<Bucket> {
    return this.http.get<Bucket>(getBaseURLs(METADATA) + `/inboundbuckets/me/${idBucket}?isAdmin=false`);
  }

  getMyOutboundBucket(idBucket: string): Observable<Bucket> {
    return this.http.get<Bucket>(getBaseURLs(METADATA) + `/outboundbuckets/me/${idBucket}?isAdmin=false`);
  }

  createBucket(form) {
    const bucketEntities = [];

    let uploadPermission = false;
    let downloadPermission = false;
    switch (form.type.value) {
      // Inbound
      case 1:
        downloadPermission = true;
        break;
      // Outbound
      case 2:
        uploadPermission = true;
        break;
      // Both
      case 3:
        uploadPermission = true;
        downloadPermission = true;
        break;
    }

    if (form.entity.length > 0) {
      form.entity.forEach(function callback(currentValue) {
        bucketEntities.push({
          idEntity: currentValue.idEntity,
          entityName: currentValue.name,
          entityCode: currentValue.entityCode,
          pUpload: uploadPermission,
          pDownload: downloadPermission,
          pAccess: false,
          pDelete: false,
          pEdit: false,
          pMove: false,
          pVersion: false,
          reportable: false
        });
      });
    }

    const body = {
      idParent: form.parentBucket ? form.parentBucket.value : null,
      name: form.name,
      alternativeKey: form.alternativeKey,
      description: form.description,
      bucketEntities: bucketEntities
    };

    return this.http.post<any>(getBaseURLs(METADATA) + `/buckets`, body);
  }

  updateBucket(idBucket: string, body: any): Observable<Bucket> {
    return this.http.put<Bucket>(getBaseURLs(METADATA) + `/buckets/${idBucket}`, body);
  }

  updateEntityBucket(idBucket: string, body: any): Observable<Bucket> {
    return this.http.put<Bucket>(getBaseURLs(METADATA) + `/buckets/${idBucket}/entity`, body);
  }

  deleteBucket(idBucket: string): Observable<Bucket> {
    return this.http.delete<Bucket>(getBaseURLs(METADATA) + `/buckets/${idBucket}`);
  }

  createBuckets(buckets: Bucket[]) {
    return this.http.post<any>(getBaseURLs(METADATA) + `/buckets/bulkload`, buckets);
  }

  validateBuckets(buckets: Bucket[]) {
    return this.http.post<any>(getBaseURLs(METADATA) + `/buckets/bulkload/validate`, buckets);
  }

  createBucketEntities(buckets: EntityBucket[]) {
    return this.http.post<any>(getBaseURLs(METADATA) + `/buckets/bulkload/bucketentities`, buckets);
  }

  validateBucketEntities(buckets, entities, bucketEntities) {
    const body = {
      buckets: buckets,
      entities: entities,
      bucketEntities: bucketEntities
    };
    return this.http.post<any>(getBaseURLs(METADATA) + `/buckets/bulkload/validate/bucketentities`, body);
  }

  async hasAccessToOutboundBuckets(): Promise<boolean> {
    try {
      const resp = await this.http.get<boolean>(getBaseURLs(METADATA) + `/outboundbuckets/me/home`).toPromise();
      this.outboundAccess.next(resp);
      return resp;
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: this.translate.instant('messages.error_message') + ` ${err.error.status}`, detail: err.error.error, sticky: true });
      return Promise.reject(err);
    }
  }
}
