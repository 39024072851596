import { Component } from '@angular/core';
import { Disclaimer } from '@shared/constants/disclaimer.constant';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year = new Date().getFullYear();

  openDisclaimer() {
    const area = JSON.parse(localStorage.getItem('area')).name.toLowerCase();
    const lang = JSON.parse(localStorage.getItem('user')).predLanguage.split('_')[0].toLowerCase();

    window.open(Disclaimer[area] ? Disclaimer[area](lang) : Disclaimer['default'](), '_blank');
  }
}
