import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetValueByFieldPipe } from './get-value-by-field.pipe';

@NgModule({
  declarations: [GetValueByFieldPipe],
  imports: [CommonModule],
  exports: [GetValueByFieldPipe]
})
export class PipesModule {}
