import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DealerForm } from '@shared/types/dealer-form.type';
import { Entity } from '@shared/types/entity.type';
import { USERS } from '@shared/utils/constants';
import { getBaseURLs } from '@shared/utils/url.util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {
  public idUser = '';

  constructor(private http: HttpClient) {}

  getEntities() {
    return this.http.get<any>(getBaseURLs(USERS)+`/entities?pageIndex=0&pageSize=100`);
  }

  getAdminEntities() {
    return this.http.get<any>(getBaseURLs(USERS)+`/entities/admin`);
  }

  getAllEntities(): Promise<Entity[]> {
    return this.http
      .get<{ content: Entity[] }>(getBaseURLs(USERS)+`/entities`)
      .toPromise()
      .then(e => Promise.resolve(e.content))
      .catch(err => Promise.reject(err));
  }

  getEntitiesLocalAdmin(): Promise<Entity[]> {
    return this.http
      .get<Entity[]>(getBaseURLs(USERS)+`/entities/local/admin`)
      .toPromise()
      .then(e => Promise.resolve(e))
      .catch(err => Promise.reject(err));
  }

  getMyEntities() {
    return this.http.get<any>(getBaseURLs(USERS)+`/entities/names/me`);
  }

  getMyEntitiesWithChilds() {
    return this.http.get<any>(getBaseURLs(USERS)+`/entities/names/me?childrenEntities=Y`);
  }

  getEntitiesByArea() {
    return this.http.get<any>(getBaseURLs(USERS)+`/entities/names/area`);
  }

  getEntitiesFilterForDocuments() {
    return this.http.get<any>(getBaseURLs(USERS)+`/documents/entitiesfrom/me`);
  }

  getEntity(idEntity: string): Observable<Entity> {
    return this.http.get<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}`);
  }

  getEntitySimpleResponse(idEntity: string): Observable<Entity> {
    return this.http.get<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}/simpleresponse`);
  }

  createEntity(form: DealerForm) {
    const body = {
      name: form.name,
      idParent: form.dealerFather ? form.dealerFather.value : null,
      entityCode: form.entityCode
    };

    return this.http.post<any>(getBaseURLs(USERS)+`/entities`, body);
  }

  createEntities(entities: Entity[]) {
    return this.http.post<any>(getBaseURLs(USERS)+`/entities/bulkload`, entities);
  }

  updateEntity(idEntity: string, body: any): Observable<Entity> {
    return this.http.put<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}`, body);
  }

  deleteEntity(idEntity: string): Observable<Entity> {
    return this.http.delete<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}`);
  }

  addUserToEntity(idEntity: string, body: any): Observable<Entity> {
    return this.http.post<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}/users`, body);
  }

  updateUserOnEntity(idEntity: string, idUser: string, body: any): Observable<Entity> {
    return this.http.put<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}/users/${idUser}`, body);
  }

  deleteUserFromEntity(idEntity: string, idUser: string): Observable<Entity> {
    return this.http.delete<Entity>(getBaseURLs(USERS)+`/entities/${idEntity}/users/${idUser}`);
  }

  validateEntities(entities: Entity[]) {
    return this.http.post<any>(getBaseURLs(USERS)+`/entities/bulkload/validate`, entities);
  }
}
