import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@shared/services/user.service';

@Injectable({ providedIn: 'root' })
export class InitDataResolver implements Resolve<void> {
  constructor(private userService: UserService, private router: Router) {}

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const promises: Promise<any>[] = [];
    if (!this.userService._user.value) {
      promises.push(this.userService.getUser());
    }
    if (!this.userService._area.value) {
      promises.push(this.userService.getAreas());
    }
    try {
      await Promise.all(promises);
    } catch (error) {
      this.router.navigateByUrl('/404');
    }
    return null;
  }
}
