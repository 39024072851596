import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { storageConstants } from '@constants/storage.constant';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG, LanguageSelector } from '@shared/constants/lang.constant';
import { Logos } from '@shared/constants/logos.constant';
import { SidebarService } from '@shared/services/sidebar.service';
import { UserService } from '@shared/services/user.service';
import { Area } from '@shared/types/area.type';
import { User } from '@shared/types/user.model';
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isNotFound = false;
  @ViewChild('appNotification', { static: false }) appNotification;

  public areas: Area[];
  public selectedArea: Area;
  public logo: string = Logos['default'];
  public icon = 'fa fa-chevron-left';
  public languageForm: FormGroup;
  public languages: SelectItem[] = LanguageSelector;
  public user$ = this.userService.user$;
  public nameSelectedArea: string;

  constructor(
    private sidebarService: SidebarService,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.setDefaultLang(DEFAULT_LANG);

    this.user$.subscribe((currentUser: User) => {
      if (currentUser && currentUser.predLanguage) {
        this.initForm(currentUser.predLanguage);
      } else {
        this.initForm(DEFAULT_LANG);
      }
    });

    if (localStorage.getItem(storageConstants.areas) !== null) {
      this.areas = JSON.parse(localStorage.getItem(storageConstants.areas));
    }
    if (localStorage.getItem(storageConstants.area) !== null) {
      this.selectedArea = JSON.parse(localStorage.getItem(storageConstants.area)).idArea;
      this.nameSelectedArea = JSON.parse(localStorage.getItem(storageConstants.area)).name.toLowerCase();
      this.logo = Logos[this.nameSelectedArea] || Logos.default;
    }
  }

  private initForm(language: string): void {
    localStorage.setItem(storageConstants.lang, language);
    this.translate.use(language);
    this.languageForm = new FormGroup({
      languageSelector: new FormControl(language, [Validators.required])
    });
  }

  changeLanguage(event: string) {
    this.translateService.use(event);
    localStorage.setItem(storageConstants.lang, event);

    const payload = {
      language: event
    };
    this.userService.updateUserLanguage(payload).then(
      () => {
        this.appNotification.loadNotifications();
        this.userService.getUser();
      },
      err => {
        this.messageService.add({ severity: 'error', summary: this.translate.instant('messages.error_message'), detail: err.error.description, sticky: true });
      }
    );
  }

  changeSideBar() {
    if (this.icon === 'fa fa-chevron-left') {
      this.icon = 'fa fa-chevron-right';
    } else {
      this.icon = 'fa fa-chevron-left';
    }

    this.sidebarService.toggleSidebarVisibility();
  }

  changeArea(event: string) {
    localStorage.setItem(
      storageConstants.area,
      JSON.stringify(
        this.areas.filter(area => {
          return area.idArea === event;
        })[0]
      )
    );

    if (this.router.url === '/home') {
      window.location.reload();
    } else {
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
      });
    }
  }
}
