import { Injectable } from '@angular/core';
import { storageConstants } from '@constants/storage.constant';
import { User } from '@shared/types/user.model';
import { getAdfsUrl } from '@shared/utils/url.util';
import { environment } from 'environments/environment';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * @deprecated
   */
  getCurrentUser(): User {
    let currentUser = null;
    if (localStorage.getItem(storageConstants.user)) {
      currentUser = new User(JSON.parse(localStorage.getItem(storageConstants.user)));
    }
    return currentUser;
  }

  isAccessTokenInvalidOrExpired(): boolean {
    const { marginTokenExpire } = environment.oauth;
    const accessToken = sessionStorage.getItem('access_token');
    return !accessToken || new Date().getTime() > this.getExpireTokenTime(accessToken) - marginTokenExpire;
  }

  private getExpireTokenTime(accessToken: string): number {
    const decodedAccessToken: JSON = jwtDecode(accessToken);
    const tokenExpireTime = decodedAccessToken['exp'];
    return new Date(tokenExpireTime * 1000).getTime();
  }

  logout() {
    this.prepareFrame(environment.oauth.openidLogout);
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(getAdfsUrl());
  }

  private prepareFrame = url => {
    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', url);
    ifrm.style.display = 'none';
    document.body.appendChild(ifrm);
  };
}
