import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { FolderSidebarComponent } from './folder-sidebar.component';

@NgModule({
  declarations: [FolderSidebarComponent],
  imports: [CommonModule, TranslateModule, PanelMenuModule, ButtonModule],
  exports: [FolderSidebarComponent]
})
export class FolderSidebarModule {}
