import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from '@shared/components/layout/layout.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { InitDataResolver } from '@shared/resolvers/InitData.resolver';
import { OAuthCallbackComponent } from './shared/components/oauth/oauth-callback.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    resolve: { data: InitDataResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },

      {
        path: 'configuration',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'inbound-bucket',
        loadChildren: () => import('./pages/inbound-buckets/inbound-buckets.module').then(m => m.InboundBucketsModule)
      },
      {
        path: 'outbound-bucket',
        loadChildren: () => import('./pages/outbound-buckets/outbound-buckets.module').then(m => m.OutboundBucketsModule)
      },

      {
        path: 'document-report',
        loadChildren: () => import('./pages/document-report/document-report.module').then(m => m.DocumentReportModule)
      }
    ]
  },
  {
    path: '404',
    canActivate: [AuthGuard],
    component: PageNotFoundComponent
  },
  {
    path: 'oauth',
    component: OAuthCallbackComponent
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
