import { ExcelCol } from '@shared/types/export.type';

export const UsersEntities: ExcelCol[] = [
  {
    field: 'entityCode',
    header: 'admin.report.entity_code'
  },
  {
    field: 'entidad',
    header: 'admin.report.entity'
  },
  {
    field: 'parentEntityCode',
    header: 'admin.report.parent_entity_code'
  },
  {
    field: 'parentEntity',
    header: 'admin.report.parent_entity'
  },
  {
    field: 'user',
    header: 'admin.report.user'
  },
  {
    field: 'name',
    header: 'admin.report.name'
  },
  {
    field: 'email',
    header: 'admin.report.email'
  },
  {
    field: 'isAdmin',
    header: 'admin.report.admin'
  },
  {
    field: 'userModificationReport',
    header: 'admin.report.user_last_modification'
  },
  {
    field: 'dateModificationReport',
    header: 'admin.report.data_time'
  }
];

export const BucketEntities: ExcelCol[] = [
  {
    field: 'entityCode',
    header: 'admin.report.entity_code'
  },
  {
    field: 'entity',
    header: 'admin.report.entity'
  },
  {
    field: 'bucket',
    header: 'admin.report.bucket'
  },
  {
    field: 'altKey',
    header: 'admin.report.alternative_key'
  },
  {
    field: 'upload',
    header: 'admin.report.upload'
  },
  {
    field: 'download',
    header: 'admin.report.download'
  },
  {
    field: 'reportable',
    header: 'admin.report.reportable'
  },
  {
    field: 'move',
    header: 'admin.report.move'
  },
  {
    field: 'edit',
    header: 'admin.report.edit'
  },
  {
    field: 'delete',
    header: 'admin.report.delete'
  },
  {
    field: 'access',
    header: 'admin.report.access'
  },
  {
    field: 'version',
    header: 'admin.report.version'
  },
  {
    field: 'userModificationReport',
    header: 'admin.report.user_last_modification'
  },
  {
    field: 'dateModificationReport',
    header: 'admin.report.data_time'
  }
];
