import { minute } from '@shared/constants/general.constant';
import { USERS, METADATA, NOTIFICATIONS, STORAGE } from '@shared/utils/constants';

export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  baseUrl: 'https://api.d01e.eu.gcp.ford.com',
  baseUrlWithoutApiPrefix: 'https://api.d01e.eu.gcp.ford.com/wsapp/folder/notifications',
  oauth: {
    openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
    openidLogout: 'https://corpqa.sts.ford.com/adfs/oauth2/logout',
    openidClientId: 'urn:docma:clientid:ui:dev',
    openidResource: 'urn:docma:resource:ui:dev',
    openidRedirectUrl: 'https://wwwdev.folder.ford.com/oauth',
    responseType: 'token+id_token',
    marginTokenExpire: 5 * minute
  },
  urls: [
    { name: USERS, url: 'https://api.d01e.eu.gcp.ford.com/folder/users/api' },
    { name: METADATA, url: 'https://api.d01e.eu.gcp.ford.com/folder/metadata/api' },
    { name: NOTIFICATIONS, url: 'https://api.d01e.eu.gcp.ford.com/wsapp/folder/notifications/api' },
    { name: STORAGE, url: 'https://api.d01e.eu.gcp.ford.com/folder/storage/api' }
  ],
  idleTime: 30 * minute
};
